//import baseTheme from 'gatsby-theme-ui/preset/src/gatsby-plugin-theme-ui'
import primeflex from "/node_modules/primeflex/primeflex.css"
import w3 from "../../w3.css"

import { keyframes } from '@emotion/react'

const boxTo = keyframes({ from: { border:" solid #0dc1da"}, to: { border:"dotted #fc65c2"} })

const theme = {
	config: {
	initialColorModeName: 'light',
  },
  colors: {
    text: "black",
	fond:"white",
    background: "#F7F7F7",
    primary: "#fc65c2",
    secondary: "#0dc1da",
	highlight: "#cecece",
	/*modes: {
      dark: {
        text: '#fff',
		fond:"#737373",
        background: 'black',
        primary: '#0dc1da',
		secondary: "#fc65c2",
		highlight: "#999999",
      }
    }*/
  },
  fonts: {
    body:'system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", sans-serif',
    heading: 'Helvetica Neue, sans-serif',
    monospace: 'Menlo, monospace',
  },
  fontWeights: {
    body: 400,
    heading: 600,
    bold: 700,
  },
  lineHeights: {
    body: 1.5,
    heading: 1.125,
  },
  letterSpacings: {
    heading: '-0.05em',
    caps: '0.1em',
  },
  text: {
    heading: {
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 'heading',
	  letterSpacing: 'heading',
    },
	display: {
      fontFamily: 'heading',
      fontWeight: 'heading',
      lineHeight: 'heading',
      letterSpacing: 'heading',
      fontSize: [ 5, 6, 7 ],
    },
	ellipsis:{
	  fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 'heading',
	  letterSpacing: 'heading',
		border:'none',
		textOverflow: "ellipsis", 
		
		whiteSpace: 'nowrap',
		overflow:'hidden'
	},
	caps: {
      textTransform: 'uppercase',
      letterSpacing: 'caps',
    },
  },
  //avatar
  images: {
    avatar: {
      width: 48,
      height: 48,
      borderRadius: 99999
    },
  },
  //box
  box: {
    header: {
	  alignItems: 'center',
	  justifyContent:'space-between',
      ml:'1%',
	  mt:'5px',
	  mr:'1%',
	  maxWidth: '98%',
	  maxHeight:'9%',
	  borderRadius:"20px",
	  color:"text"
    },
	elevation:{
		boxShadow:" 0px 4px 10px rgba(0, 0, 0, 0.03), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.12)",
		'&:hover': {
			boxShadow: ' 0px 1px 8px rgba(0, 0, 0, 0.08), 0px 3px 4px rgba(0, 0, 0, 0.1), 0px 1px 4px -1px rgba(0, 0, 0, 0.1)',
			transition: "boxShadow 3s"
		  }
	},
	beyou:{
		p:'1%',
		background:['linear-gradient(180deg, #fc65c2,white,#F7F7F7, #0dc1da)','none','none'], 
		'&:active': {
			borderRadius:36,
			borderTop: "1px solid #fc65c2",
			borderBottom: "1px solid #0dc1da"
			}
	},
	anim:{
		animation: `${boxTo} 1s ease-in-out infinite`
	}
  },
  //navlink
  links: {
    nav: {
      px: 2,
      py: 1,
      textTransform: 'uppercase',
      letterSpacing: '0.2em',
    }
  },
  //Button
  buttons: {
    primary: {
      color: 'background',
      background: "primary",//'radial-gradient(farthest-corner at center, #FFFFFF 0%, rgba(252, 101, 194, 0.7) 24%,lightGray 62.5%, rgba(13, 193, 218, 0.8) 100%)',
      '&:hover': {
        bg: '#fd66c3',
		boxShadow:" 0px 4px 10px rgba(0, 0, 0, 0.03), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.12)"
      },
	  "::before":{
		  transition: "box-shadow 0.3s ease-in-out"
	  }
    },
    secondary: {
      color: 'background',
      bg: 'secondary',
	  '&:hover': {
        bg: '#0f3b61',
      }
    },
	mobile:{
		background:'linear-gradient(144deg,lightGray,#F6BA7D,#f96352,#f952a6,#F952B3)',
		color:'white',
		borderRadius:27,
		justifySelf:'center',height:'auto', width:'95vw',p:'2vw',
		'&:hover': {
			bg: 'linear-gradient(216deg,lightGray,#F6BA7D,#f96352,#f952a6,#F952B3)',
			color:'black',
		  }
	},
	reverse: {
      color: 'primary',
      background: "background",//'radial-gradient(farthest-corner at center, #FFFFFF 0%, rgba(252, 101, 194, 0.7) 24%,lightGray 62.5%, rgba(13, 193, 218, 0.8) 100%)',
      fontWeight:'bolder',
	  '&:hover': {
        bg: 'background',
		boxShadow:" 0px 4px 10px rgba(0, 0, 0, 0.03), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.12)"
      },
	  "::before":{
		  transition: "box-shadow 0.3s ease-in-out"
	  }
    },
	clean:{background:'none',border:'none',boxShadow:'none','&:hover':{background:'none',border:'none',boxShadow:'none'}},
	mob:{
      color: 'text',
      background: 'linear-gradient(180deg, #fc65c2,white,#F7F7F7, #0dc1da)',
	  boxShadow: 'inset 8px 16px -4px red ',
	  '&:active': {
        bg: 'background',
		boxShadow: "inset 0 0 4px #C0C0C0,0 0 8px #848884 "
      }
    },
  },
  fontSizes: [12, 14, 16, 20, 24, 32, 48, 64, 72],
  space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
  styles: {
    root: {
      fontFamily: 'body',
      lineHeight: 'body',
      fontWeight: 'body',
    },
    h1: {
      variant: 'text.heading',
      fontSize: 5,
    },
    h2: {
      variant: 'text.heading',
      fontSize: 4,
    },
    h3: {
      variant: 'text.heading',
      fontSize: 3,
    },
    h4: {
      variant: 'text.heading',
      fontSize: 2,
    },
    h5: {
      variant: 'text.heading',
      fontSize: 1,
    },
    h6: {
      variant: 'text.heading',
      fontSize: 0,
    },
    pre: {
      fontFamily: 'monospace',
      overflowX: 'auto',
      code: {
        color: 'inherit',
      },
    },
    code: {
      fontFamily: 'monospace',
      fontSize: 'inherit',
    },
    table: {
      width: '100%',
      borderCollapse: 'separate',
      borderSpacing: 0,
    },
    th: {
      textAlign: 'left',
      borderBottomStyle: 'solid',
    },
    td: {
      textAlign: 'left',
      borderBottomStyle: 'solid',
    },
  },
}

export default theme
